<template>
  <button>
    <span class="svg-icon">
      <svg>
        <use xlink:href="#cx-men1-download-sample"></use>
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  name: "DownloadLogButton",
  methods: {}
};
</script>
